import {FetchJson, PostJsonDynamo, getResults, getAssessmentResults, getAssessmentsDataFromValuesV4, NWEADashboardAPI, NWEAResultAPI, AISAPIV4, FetchJsonDynamo} from './Helper';
import {nweaDynamoOrgService} from "./nweaDynamoOrgService";
import { validateGuid } from "../utils/validationutils";

export const nweaDynamoService = {

  searchDistrictsAndSchools: async (req) => {
    let searchbyid = req.id.length !== 0 || validateGuid(req.id);
    return nweaDynamoOrgService.searchDistrictsAndSchools({...req, searchByGuid:searchbyid});
  },

  searchSchools: async (req) => {
    let searchName=req.params.searchName;
    let searchByGuid=searchName.length > 0 && validateGuid(searchName);
    let searchedSchool = searchByGuid && req.id !== searchName && req.params.searched;
    
    if (searchedSchool) {
      return nweaDynamoOrgService.getSchool({...req,id:searchName,isSchool:true,searchByGuid:searchByGuid}).then((resp) => {        
        return { ...resp, values: getResults(resp.values, true,req.dtName) };
      });
    }
    else {
      return nweaDynamoOrgService.getSchoolsByDistrictV4(req);
    }
  },

  getStudents: async (req) => {
    if (req.params.searched) {      
      return nweaDynamoOrgService.getStudentsByStudentIdV4(req);
    }
    else {
      let apiResponse = await nweaDynamoOrgService.getStudentsByOrgV4(req);
      let aggregatedResponse = [];
      for (const element of apiResponse.values) {
        aggregatedResponse.push(element);    
      }

      return {...{ size:aggregatedResponse.length, values:aggregatedResponse, paginationtoken:apiResponse.paginationtoken}, values: aggregatedResponse}
    }
  },

  getAssessmentlog: async (days) => {
    const url = `${NWEADashboardAPI}/assessmentlog?days=$(days)`;
    return FetchJson(url);
  },

  getResultsByOrgByStudent: async (req) => {
    let org = req.isSchool ? "schools" : "districts";
    let studentId=req.id;    
    const url = `${NWEAResultAPI}/${org}/${req.params.org.id}/students/${studentId}?page=${req.page}&pageSize=${req.pageSize}&startDate=${req.params.StartDate}&endDate=${req.params.EndDate}`;   
    return FetchJson(url).then((resp) => {
      return { ...resp, values: getAssessmentResults(resp.values) };
    });
  },

  getResultsByOrgByStudentV4: async (req) => {
    const url = `${AISAPIV4}/districts/${req.districtId}/students/${req.id}/assessments?startDate=${req.params.StartDate}&endDate=${req.params.EndDate}`;
    return FetchJsonDynamo(url).then((resp) => {
      let result = { ...resp, values: getAssessmentsDataFromValuesV4(resp.values) };
      return result;
    });
  },

  syncV4NweaAllDistricts: async (req) => {
    const url = `${AISAPIV4}/sync/districts?VendorType=Nwea&UserId=${req.userinfo.email}`;
    return PostJsonDynamo(url, req);
  },

  syncV4NweaSchoolsByDistrict: async (req) => {
    let districtId = req.params.id;
    const url = `${AISAPIV4}/sync/districts/${districtId}/schools?VendorType=Nwea&UserId=${req.userinfo.email}`;
    return PostJsonDynamo(url, req);
  }
};
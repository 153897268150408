import { useContext} from "react";
import DatePicker from "../DatePicker";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import {DataContext} from "../DataContext";
 const Content = (props) => {
   const context=useContext(DataContext);
   const {action,name,handleDateChange,defaultdate}=props;
  switch (action) {
    case "SyncNweaDistricts":
    case "SyncNweaDynamoSchools::1":
    case "AllDistrict":
    case "District::1":
    case "District::2":
    case "School::1":  
      return (
        <div>
          <span>
            {context.GetBodyLabel(action,name)}
            <i>{context.GetItalicsLabel(action)}</i>
          </span>
        </div>
      );
    case "School::2":
    case "RenSchool::1":
      return (
        <div>
          <span>
           {context.GetBodyLabel(action,name)}
            <i>
            {context.GetItalicsLabel(action)}
            </i>
          </span>
          <div><DatePicker
            handleDateChange={handleDateChange}
            defaultValue={defaultdate}
          /></div>
        </div>
      );
    default:
      return <></>;
  }
};
const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
export default function CustomDialogContent(props){
    return  (<DialogContent>  <Content {...props}/> </DialogContent>)
}
import {FetchJson, getDistrictsDataFromValuesV4, getStudentsDataFromValuesV4, NWEAOrgAPI, AISAPIV4, FetchJsonDynamo} from './Helper';
import {CacheService} from "./CacheService";

export const nweaDynamoOrgService  = {

  getDistrictsV4: async (req) => {
    let url = `${AISAPIV4}/districts${req.id.length<=0?"":"/"+req.id}?vendortype=Nwea${req.params.pageSize!==undefined?"&pageLimit="+req.params.pageSize:"150"}${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`;   

    return FetchJsonDynamo(url).then((resp) => {
      return resp;
    });
  },

  getSchool: async (req) => { 
    const url = `${NWEAOrgAPI}/schools/${req.id??""}?page=${req.page}&pageSize=${req.pageSize}`;        
    let key = {id:req.id,Name:"getSchool",Params:{Page:req.page,PageSize:req.pageSize}};   
    return CacheService.GetCacheData(key).then((data)=>{   
      if (data) {
        return data;
      } else {        
        return FetchJson(url).then((resp) => {         
          CacheService.PutCacheData({...key, Data:resp});
          return resp;
        });}}
        );
  },


  getSchoolsByDistrictV4: async (req) => {
    const url = `${AISAPIV4}/districts/${req.districtId}/schools?&pageLimit=2000${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`; 

    return FetchJsonDynamo(url).then((resp) => {          
      return {...{ size:resp.values.length, values:resp.values, paginationtoken:null}, values: getDistrictsDataFromValuesV4(resp.values, true)}
    });
  },

  getStudentsByOrgV4: async (req) => {

    let url = '';
    if(req.schoolId)
    {
      url = `${AISAPIV4}/districts/${req.districtId}/schools/${req.schoolId}/students?${req.params.pageSize!==undefined?"pageLimit="+req.params.pageSize:"150"}${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`;
    }
    else
    {
      url = `${AISAPIV4}/districts/${req.districtId}/students?${req.params.pageSize!==undefined?"pageLimit="+req.params.pageSize:"150"}${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`;
    }
     
    return FetchJsonDynamo(url).then((resp) => {
      resp.values = getStudentsDataFromValuesV4(resp.values);
      return resp;
    });
  },

  getStudentsByStudentIdV4: async (req) => {

    let url = `${AISAPIV4}/districts/${req.districtId}/students/${req.id}`;
        
    return FetchJsonDynamo(url).then((resp) => {
      resp.values = getStudentsDataFromValuesV4(resp.values);
      return resp;
    });
  },

  searchDistrictsAndSchools: async (req) => {     
    let apiResponse = await nweaDynamoOrgService.getDistrictsV4(req);
    let aggregatedResponse = [];
    for (const element of apiResponse.values) {
      aggregatedResponse.push(element);      
    }    
    return {...{ size:aggregatedResponse.length, values:aggregatedResponse, paginationtoken:apiResponse.paginationtoken}, values: getDistrictsDataFromValuesV4(aggregatedResponse)}
  }
}
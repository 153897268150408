import { nweasyncservice } from "../../Services/nweasyncservice";
import { nweaDynamoService } from "../../Services/nweaDynamoService";
import {renService} from "../../Services/renService";

const callAPIByAppTypes= async (syncService, inp) => {
    let req = {
      userinfo: inp.payload,
      apptype: "All",
      params:inp.params
    }; 
    syncService(req);
 //   syncService({ ...req, apptype: "ImagineMath" });
  }

export const syncServiceReducer = async (action) => {
  let request = { payload: action.payload, params: action.params };
  switch (action.type) {
    case "SyncNweaDistricts":
      return callAPIByAppTypes(nweaDynamoService.syncV4NweaAllDistricts, request)
        .then((result) => {
            return { success: true };
          }
        );

    case "SyncNweaDynamoSchools::1":
      return callAPIByAppTypes(nweaDynamoService.syncV4NweaSchoolsByDistrict, request)
        .then((result) => {
            return { success: true };
          }
        );

    case "AllDistrict":
      return callAPIByAppTypes(nweasyncservice.syncAllDistricts
        , request
        ).then(() => {
          return { success: true };
        }
      );
    case "District::1": //First Button on District Row
      return callAPIByAppTypes(
        nweasyncservice.syncStudentsOfDistrict,
        request
      ).then(() => {
        return { success: true };
      });
    case "District::2": //Second Button on District Row
      return callAPIByAppTypes(
        nweasyncservice.syncSchoolsOfDistrict,
        request
      ).then(() => {
        return { success: true };
      });
    case "School::2": //Second Button on School Row
      return callAPIByAppTypes(
        nweasyncservice.syncAssessmentsBySchool,
        request
      ).then(() => {
        return { success: true };
      });
      case "RenSchool::1":
        renService.syncStudentAssessments(request);
          return { success: true };
      case "School::1": //First Button on School Row
          return callAPIByAppTypes(
            nweasyncservice.syncStudentsOfSchool,
            request
          ).then(() => {
            return { success: true };
          });
    default:
      return { success: false };
  }
};